import React from 'react';
import '../../App.css';
import './SellPage.css';
import Navigation from '../../Components/Navigation/Navigation.jsx';
import ButtonZakaz from '../../Components/UI/ButtonZakaz.jsx';

const SellPage = () => {
  return (
    <div className="container">
      <div className='containerSellPage'>
      <Navigation/>
        <h2 className='sellPageh2'>Натальная карта - это астрологический "снимок" неба в момент вашего рождения.
           Она содержит информацию о положении планет и других небесных тел, которая влияет на вашу личность, характер, таланты и предрасположенности. 
        </h2>
        <h2 className='sellPageh2'>Цены на услуги:</h2>
        <div className='sellContainer'>
            <h3 className='sellPageh3'>Взрослая натальная карта</h3>
            <ul>
              <li>&#10004; Понимание себя: Астрология позволяет разобраться в своих мотивах, желаниях, целях и страхах.</li>
              <li>&#10004; Профессиональная ориентация: Анализ карты может помочь определиться с профессией, которая будет приносить удовлетворение и успех.</li>
              <li>&#10004; Любовные отношения: Астрология позволяет проанализировать совместимость с партнером и понять динамику отношений.</li>
              <li>&#10004; Преодоление препятствий: Астрология помогает определить причины возникновения проблем и найти способы их решения.</li>
              <li>&#10004; Развитие потенциала: Анализ карты показывает наиболее благоприятные направления для развития личности, реализации талантов и достижения успеха.</li>
            </ul>
            <ButtonZakaz source={'https://www.t.me/nastiay_sol'} text={'3999p'}/>
        </div>
        <div className='sellContainer'>
          <h3 className='sellPageh3'>Детcкая натальная карта</h3>
          <ul>
              <li>&#10004; Фокус на потенциале: Основное внимание уделяется внутреннему потенциалу ребенка, его талантам, склонностям и сильным сторонам. </li>
              <li>&#10004; Гармония и развитие: Астрологическая карта помогает понять, как создать благоприятные условия для гармоничного развития ребенка, исходя из его индивидуальных астрологических особенностей. </li>
              <li>&#10004; Понимание темперамента: Астрология помогает понять темперамент ребенка, его образ мышления, эмоциональность и способность адаптироваться к новым ситуациям. </li>
              <li>&#10004; Выбор обучения: Анализ карты может помочь родителям выбрать подходящий тип обучения и воспитания, соответствующий астрологическим склонностям ребенка. </li>
          </ul>
          <ButtonZakaz source={'https://www.t.me/nastiay_sol'} text={'2999p'}/>
        </div>
        <div className='sellPageFooter'>
                  <h2 className='sellPageh2'> Важно помнить:</h2> 

            <p>&#9733; Астрология - это не гадание и не предсказание будущего. Она позволяет понять тенденции и возможности, заложенные в вас с рождения.</p> 
            <p>&#9733; Ваше будущее в ваших руках. Астрология может стать инструментом самопознания и саморазвития, но она не заменяет вас как личность.</p> 
        </div>
      </div>
  </div>
  );
}

export default SellPage;