import React from 'react';
import '../../App.css';
import './AboutPage.css';
import Navigation from '../../Components/Navigation/Navigation.jsx';
import logo1 from '../../assets/img/photo1.jpg';
import ButtonZakaz from '../../Components/UI/ButtonZakaz.jsx';
import ButtonContainer from '../../Components/ButtonContainer/ButtonContainer.jsx';

const AboutPage = () => {
  return (
    <div className="container">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
           
           <div className='containerAboutPage'>
            <Navigation/>
            <div className='tgAbout'>
            <ButtonContainer/>
            </div>
            <div className='containerAboutPageImg'>
              <div className='aboutPageImg'>
                <img src={logo1} alt="" />
              </div>
              <div className='aboutPageAbout1'>
                <h2 className='h2AboutPage'> Откройте тайны звёзд вместе со мной</h2>

                <h3 className='h3AboutPage'>Я - астропсихолог, помогающий людям раскрыть свой потенциал и обрести гармонию с собой и миром.</h3>
            
                <h4 className='h4AboutPage'>С помощью астрологии я анализирую индивидуальную карту рождения, выявляя скрытые таланты, сильные и слабые стороны личности, а также указываю на наиболее благоприятные направления для самореализации. </h4>
              </div>
            </div>
            <div className='aboutPageAbout2'>
                <h3 className='h3AboutPage'>В чём я могу помочь:</h3>
                <ul>
                  <li>&#10004;  Понять себя глубже: узнать свои истинные желания, мотивы и ценности.
                  </li>
                  <li>&#10004;  Построить успешную карьеру: определиться с подходящим направлением деятельности, где вы будете чувствовать себя комфортно и продуктивно.
                  </li>
                  <li>&#10004; Найти свою любовь: разобраться в совместимости с партнёром и построить гармоничные отношения.</li>
                  <li>&#10004; Справиться с трудностями: определить причины возникновения проблем и найти пути их решения.</li>
                  <li>&#10004; Понять свои сильные стороны: использовать свои таланты и способности для достижения поставленных целей.</li>
                </ul>
                <h3 className='h3AboutPage'>Мои услуги:</h3>
                <ul>
                  <li>&#9733; Индивидуальные консультации по астропсихологии: глубокий анализ вашей карты рождения, ответы на ваши вопросы и рекомендации по развитию.</li> 
                  <li>&#9733; Астрологические прогнозы: прогнозы на текущий год, месяц, неделю, а также на важные события в вашей жизни.</li>
                  <li>&#9733; Составление индивидуальных гороскопов: на любовь, карьеру, здоровье и другие сферы жизни.</li>
                </ul>
            </div>
              <h2 className='h2AboutPage'> Я предлагаю вам уникальную возможность посмотреть на себя с нового ракурса и раскрыть потенциал, заложенный в вас с рождения.</h2>
              
               <ButtonZakaz source={'https://www.t.me/nastiay_sol'} text={'записаться на консультацию'}/>
               <h3 className='h3AboutPage'>Пусть звёзды помогут вам найти свой путь!</h3>  
           </div>
    </div>
  );
}

export default AboutPage;