import React from "react";
import logo1 from '../../assets/icon/tg.webp';
import logo2 from '../../assets/icon/instagram-responds-to-photo-usage-rumors.jpg';
import './ButtonContainer.css'
import CustomButton from '../UI/CustomButton';

function ButtonContainer(){
    return(
<div className="button_container">
                <div className="button">
                        <CustomButton text={'nastiya_sol'} source={'https://www.t.me/nastiya_sol'} logo = {logo1} variant={'telegram'}/>
                </div>
                <div className="button">
                        <CustomButton text={'nastiya_sol'} source={'https://www.instagram.com/nastiya_sol?igsh=a2FybDFncHh0NHR1'} logo = {logo2} variant={'instagram'}/>
                </div>
</div>
    )
}

export default ButtonContainer