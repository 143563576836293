import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";
import AboutPage from "../pages/AboutPage/AboutPage";
import AnswerPage from "../pages/AnswerPage/AnswerPage";
import SellPage from "../pages/SellPage/SellPage";


const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <HomePage/>
        },
        {
            path: '/about',
            element: <AboutPage/>
        },
        {
            path: '/answer',
            element: <AnswerPage/>
        },
        {
            path: '/sell',
            element: <SellPage/>
        }

    ]
)

export default router