import React from 'react';
import '../../App.css';
import ButtonContainer from '../../Components/ButtonContainer/ButtonContainer.jsx';
import ContainerMainAbout from '../../Components/ContainerMainAbout/ContainerMainAbout.jsx';
import ButtonZakaz from '../../Components/UI/ButtonZakaz.jsx';
import Navigation from '../../Components/Navigation/Navigation.jsx';
const HomePage = () => {
  return (
    <div className="container">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        <section className="header_section">
            <Navigation/>
            <div className="header_container">
                <ContainerMainAbout/>
                <ButtonZakaz source={'https://www.t.me/nastiay_sol'} text={'заказать консультацию'}/>
                <ButtonContainer/>
            </div>
        </section>
    </div>
  );
}

export default HomePage;