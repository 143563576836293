import React from 'react'
import "./Navigation.css"
import NavigationLink from '../UI/NavigationLink/NavigationLink'

function Navigation(){
    return(
        <nav className='main_navigation'>
          <li className={'main_navigation__item'}>
          <NavigationLink text={'Главная'} path={"/"}/>
          </li>
          <li className={'main_navigation__item'}>
          <NavigationLink text={'О себе'} path={"/about"}/>
          </li>
          <li className={'main_navigation__item'}>
          <NavigationLink text={'Отзывы'} path={"/answer"}/>
          </li>
          <li className={'main_navigation__item'}>
          <NavigationLink text={'Цены'} path={"/sell"}/>
          </li>
        </nav>
    )
}
export default Navigation