import React from "react";
import './ContainerMainAbout.css';

function ContainerMainAbout(){
    return(
        <div className="container_main_about">
                <h1>Анастасия <br/>
                    Соломенникова</h1>
                <h5>Практикующий астропсихолог</h5>
                <h6>Даю пошаговую инструкцию к лучшей версии твоей жизни</h6>
        </div>
    )
}

export default ContainerMainAbout