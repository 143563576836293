import React from 'react'
import './CustomButton.css'
import './CustomButton'


function CustomButton({text,source,logo,variant}){
return(
        <p>
            <a href={source}>
                <img src={logo} alt={variant} />
                {text}
            </a>
            
        </p>
)
}
export default CustomButton