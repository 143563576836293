import React from 'react';
import '../../App.css';
import './AnswerPage.css';
import logo1 from '../../assets/img/logo1.jpg';
import logo2 from '../../assets/img/logo2.jpg';
import logo3 from '../../assets/img/logo3.jpg';
import logo4 from '../../assets/img/logo4.jpg';
import logo5 from '../../assets/img/logo5.jpg';
import logo6 from '../../assets/img/logo6.jpg';
import Navigation from '../../Components/Navigation/Navigation';


const AnswerPage = () => {
  return (
    <div className="container">   
      <section className='sectionImgLogo'>
        <Navigation/>
        <div className="imgLogo1">
            <div className="imLogo"><img src={logo1} alt="" /></div>
            <div className="imLogo"><img src={logo4} alt="" /></div>
        </div>
        <div className='imgLogo2'>
            <div className="imLogo"><img src={logo3} alt="" /></div>
            <div className="imLogo"><img src={logo6} alt="" /></div>
        </div>
        <div className='imgLogo3'>
          <div className="imLogo"><img src={logo2} alt="" /></div>
          <div className="imLogo"><img src={logo5} alt="" /></div>
        </div>
      </section>
    </div>
  );
}

export default AnswerPage;